import styled from 'styled-components';

export const editor = styled.div`
  flex: 4 1 0%;
  max-width: 720px;
  font-family: 'IBM Plex Serif', 'Segoe UI', Tahoma, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgb(10, 35, 92);
  padding-top: 20px;
  cursor: text;
  align-self: flex-start;
`;

export const asideLeft = styled.aside`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  min-width: 304px;
`;

export const asideRight = styled.aside`
  height: 100%;
  flex: 1 1 0%;
  min-width: 304px;
  display: flex;
`;

export const asideRightInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5rem;
  margin-right: 1rem;
  width: 252px;
`;

// TODO: IS THIS NEED?
export const left = styled.div`
  grid-row-end: span 3;
`;

// TODO: IS THIS NEED?
export const centerTop = styled.div``;

// TODO: IS THIS NEED?
export const centerMain = styled.div`
  grid-area: center-main;
  display: flex;
  overflow: auto;

  min-height: 32px;
  opacity: 0.8;
  color: #0a235c;
  font-family: 'IBM Plex Serif', 'Segoe UI', Tahoma, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  border: 0;
`;

//TODO: IS THIS NEED?
export const rightTop = styled.div`
  width: 240px;
  margin-bottom: 32px;
  margin-left: 90px;
`;

export const rightMain = styled.div`
  grid-area: sidebar-main;
  justify-items: end;
`;

export const rightBottom = styled.div`
  grid-area: sidebar-low;
`;

export const editorSuggestion = styled.span`
  border-bottom: 1px solid #fde98f;
  box-shadow: 0 -3px 0 #fde98f inset;
  cursor: pointer;
`;

export const editorSuggestionActive = styled.span`
  background: #fde98f;
`;

export const error = styled.div`
  margin-left: 106px;
  margin-top: 8px;
  width: 210px;
  color: red;
`;

// TODO: IS THIS NEED?
export const suggestion = styled.span`
  background: #fff;
  border: 1px solid #0a235c;
  border-radius: 4px;
  color: #0a235c;
  font-size: 1.2rem;
  padding: 0 0.8rem;
  margin-right: 8px;
  cursor: pointer;
  display: inline-block;
  margin-top: 8px;
`;

// TODO: IS THIS NEED?
export const button = styled.button`
  background: linear-gradient(135deg, #338bff 0%, #0055ff 100%);
  border: 0 none;
  border-radius: 0.8rem;
  box-shadow: 0 4px 16px -4px rgba(0, 96, 255, 0.5);
  color: #fff;
  font-size: 16px;
  height: 40px;
  padding: 0 3.2rem;
  text-align: center;
  font-family: 'IBM Plex Sans', 'Segoe UI', Tahoma, sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  width: 180px;
  margin: auto 70px auto auto;
  display: block;

  &:disabled {
    background: lightgrey;
    cursor: initial;
  }

  @media (max-width: 1200px) {
    margin-right: 38px;
  }
`;
