import BuyArea from '../../components/buy-area';

import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';
import { useEffect } from 'react';
import { usePreviewMachine } from '../../state/preview.machine';
import { State } from 'xstate';
import { TContext, TEvent, TState } from '../../state/document.machine';

type Props = {
  documentState: State<TContext, TEvent, any, TState>;
};

const EditorWorkspace: React.FC<Props> = ({ documentState }) => {
  const [previewState, send] = usePreviewMachine();
  useEffect(() => {
    if (documentState.context.preview && documentState.context.sessionId) {
      send({
        type: 'INITIALIZE_PREVIEW_TEXT',
        text: documentState.context.preview,
        sessionId: documentState.context.sessionId,
      });
    }
  }, [send, documentState.context.preview, documentState.context.sessionId]);
  return (
    <>
      <s.asideLeft />
      <s.editor>
        <Preview
          onSuggestionClick={(suggestionId: string) => () => {
            send({ type: 'SELECT_SUGGESTION', suggestionId });
          }}
          selectedSuggestionId={previewState.context.selectedSuggestionId}
          sentences={previewState.context.sentences}
        />
        <WordLimitFooter />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner>
          <SuggestionsBrowser />
          <BuyArea />
        </s.asideRightInner>
      </s.asideRight>
    </>
  );
};

export default EditorWorkspace;
