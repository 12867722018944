import styled from 'styled-components';

import { GetFileButton } from '../routes/main-page/components/get-file-button';
import { PRICE } from '../util/constants';

const BuyArea: React.FC = () => {
  return (
    <Wrapper>
      <Text>
        Your Paperpal Edited Word file includes all our edits as tracked changes in your manuscript.
      </Text>
      <GetFileButton fullWidth={true} gaPlace="sidebar" price={PRICE}>
        Download your file
      </GetFileButton>
    </Wrapper>
  );
};

export default BuyArea;

const Wrapper = styled.div`
  position: sticky;
  bottom: 32px;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Text = styled.div`
  margin: 0.5rem 0 1rem 0;
`;
